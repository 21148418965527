<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">信息扁平化</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">数字体系所认为的管理扁平化涉及5个方面，如图所示：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/14.png"
        class="SonImgW"
        style="width: 280px"
      />
      <p class="SmallP">
        从图中可以看出，管理扁平化的本质是信息扁平化。每个信息都涉及三个维度，如图所示：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/15.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="SmallP">具体的例子如：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/16.png"
        class="SonImg"
      />
      <br />
      <br />
      <br />
      <p class="SmallP">
        从上表中看到，信息流分横向和纵向。信息扁平化的计算公式如下：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/17.png"
        class="SonImg"
      />
      <p class="SmallP" style="padding: 5px 0">该指数表明：</p>
      <div class="PList">
        <p style="padding-left: 3%">
          1.多层级的组织结构是信息流速度的障碍，信息流的时间可能大部分被信息把关的流转时间占去；层级越多，信息的纵向流就越长，信息流就越受阻，组织就越迟钝；
        </p>
        <p style="padding-left: 3%">
          2.若价值链的同步活动、并行活动多，信息扁平化指数就越大，信息流的速度就越快。
        </p>
        <p>
          因此，要想提升信息流效率，就要想方设法增加同步活动、半同步活动，甚至创造同步活动。找出同步活动的关键是找出能独立空出时间和人手的地方、找出作业准备的活动。要想提升体系的效率就要将垂直的组织结构变为网络结构，将信息权下放，减少信息把关的层级。确定信息权的最好方法是过程方法，并据此建立责权层级表。
        </p>
        <p>
          在数字体系中，信息扁平化指数大于1是最基本的要求，小于1体系就会迟钝，需要改善。对于跨部门的作业、项目管理、面向市场的作业，尤其对于强调灵活性、变化大和不确定的活动该指数越大越好。目前该指数小于1的组织非常普遍，组织只有信息扁平化才能扭转这一现象。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>